<template>
    <div class="col-md-12">
        <div class="card border-0 bg-gray-800 text-white">
            <div class="card-body">
                <div class="border-0 text-gray-500">
                    <b>{{ $t('str.msg.analysis.events.perday').toUpperCase() }}</b>
                </div>
            </div>
            <div class="card-body ps-0 py-0">
                <apexchart :key="componentKey" id="chart" ref="chart" type="bar" width="100%" height="300" :options="chart.options" :series="chart.series"></apexchart>
            </div>
        </div>
    </div>
</template>

<script>
import ApexCharts from 'apexcharts'
import pt from 'apexcharts/dist/locales/pt-br.json'
import en from 'apexcharts/dist/locales/en.json'
import moment from 'moment'
export default {
    name: 'EventsPerDay',
    props: ['data', 'redirect', 'locale'],
    watch: {
        data: {
            deep: true,
            handler(newData) {
                if (newData) {
                    this.processData(newData)
                }
            },
        },
        locale: {
            immediate: true,
            handler() {
                this.chart.options.chart.defaultLocale = this.locale || 'pt-br'
                this.processData(this.data)
                ApexCharts.exec('chart', 'updateLocale', this.locale)
            },
        },
    },
    data() {
        return {
            componentKey: 0,
            chart: {
                series: [],
                options: {
                    colors: ['#32a932', '#db1d04'],
                    chart: {
                        height: '100%',
                        type: 'bar',
                        toolbar: { show: false },
                        locales: [pt, en],
                        defaultLocale: this.locale || 'pt-br',
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded',
                        },
                    },
                    xaxis: {
                        type: 'category',
                        categories: [],
                        labels: {
                            style: { colors: '#fff' },
                        },
                    },
                    yaxis: {
                        labels: {
                            style: { colors: '#fff' },
                        },
                    },
                    tooltip: {
                        y: {
                            formatter: val => `${val} ${this.$t('str.patrols')}`,
                        },
                    },
                    fill: {
                        opacity: 1,
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'right',
                        labels: {
                            colors: '#fff',
                        },
                    },
                },
            },
        }
    },
    methods: {
        handleClick(item) {
            const params = this.redirect.params

            if (params) {
                if (!params?.account) delete params.account
                if (!params?.client) delete params.client
                if (!params?.site) delete params.site

                switch (item.name) {
                    case 'COMPLETE_EVENTS':
                        params.report = 'PATROL_POINTS_COMPLETED'
                        this.$router.push({ path: '/reports/patrols/' + JSON.stringify(params) })
                        break
                    case 'INCOMPLETE_EVENTS':
                        params.report = 'PATROL_POINTS_INCOMPLETED'
                        this.$router.push({ path: '/reports/patrols/' + JSON.stringify(params) })
                        break
                }
            }
        },
        getUniqueDates(statusArray) {
            const dateSet = new Set()
            statusArray.forEach(item => {
                item.data.forEach(([, date]) => dateSet.add(date))
            })
            return Array.from(dateSet).sort((a, b) => new Date(a) - new Date(b))
        },
        processData(newData) {
            if (newData && newData?.status) {
                const categories = this.getUniqueDates(newData.status)

                this.chart.series = newData.status.map(item => ({
                    name: this.$t(item.name),
                    data: categories.map(date => {
                        const match = item.data.find(([, d]) => d === date)
                        return match ? match[0] : 0
                    }),
                }))

                this.chart.options.xaxis.categories = categories.map(date =>
                    moment(date)
                        .utc(false)
                        .format(this.locale === 'pt-br' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'),
                )

                this.$refs.chart.updateOptions({
                    xaxis: { categories: this.chart.options.xaxis.categories },
                })
            }
        },
        updateChartLocale(newLocale) {
            const updatedSeries = this.chart.series.map(seriesItem => ({
                ...seriesItem,
                name: this.$t(seriesItem.name),
            }))

            const updatedCategories = this.chart.options.xaxis.categories.map(date =>
                new Date(date).toLocaleDateString(newLocale || 'pt-br', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                }),
            )

            this.chart.series = updatedSeries
            this.chart.options.chart.defaultLocale = newLocale
            this.chart.options.xaxis.categories = updatedCategories
            this.$refs.chart.updateOptions(this.chart.options, true, false)
            this.$refs.chart.updateSeries(this.chart.series)
        },
    },
}
</script>

<style lang="scss" scoped>
.apexcharts-legend {
    position: initial !important;
}

.apexcharts-legend-series {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 5px;
}
</style>
