import moment from 'moment'

export const JSON_FIELDS_CSV = {
    sosAlert: {
        pt: {
            json_fields: {
                Vigilante: 'vigilant',
                Data: 'date',
                Latitude: 'latitude',
                Longitude: 'longitude',
                'Marca aparelho': 'deviceInfoBrand',
                'Modelo aparelho': 'deviceInfoModel',
                Atendido: 'isAttendance',
                Status: 'attendanceStatus',
                Operador: 'operator',
                Abertura: 'openedDate',
                Fechamento: 'closedDate',
                Empresa: 'account',
                Cliente: 'client',
                Local: 'site',
            },
            json_data: [
                {
                    vigilant: '',
                    date: '',
                    latitude: '',
                    longitude: '',
                    deviceInfoBrand: '',
                    deviceInfoModel: '',
                    isAttendance: '',
                    attendanceStatus: '',
                    operator: '',
                    openedDate: '',
                    closedDate: '',
                    account: '',
                    client: '',
                    site: '',
                },
            ],
            json_meta: [
                [
                    {
                        key: 'charset',
                        value: 'utf-8',
                    },
                ],
            ],
            pdfTitle: 'Relatório de alertas de SOS',
            title: 'Alertas de SOS - ' + 'Relatório gerado em: ' + moment().format('DD/MM/YYYY') + ' às ' + moment().format('HH:mm:ss'),
            filename: 'alertas_sos_' + moment().format('DD/MM/YYYY'),
        },
        en: {
            json_fields: {
                Vigilant: 'vigilant',
                Date: 'date',
                Latitude: 'latitude',
                Longitude: 'longitude',
                'Device brand': 'deviceInfoBrand',
                'Device model': 'deviceInfoModel',
                Attendance: 'isAttendance',
                Status: 'attendanceStatus',
                Operator: 'operator',
                Opened: 'openedDate',
                Closed: 'closedDate',
                Account: 'account',
                Client: 'client',
                Local: 'site',
            },
            json_data: [
                {
                    vigilant: '',
                    date: '',
                    latitude: '',
                    longitude: '',
                    deviceInfoBrand: '',
                    deviceInfoModel: '',
                    isAttendance: '',
                    attendanceStatus: '',
                    operator: '',
                    openedDate: '',
                    closedDate: '',
                    account: '',
                    client: '',
                    site: '',
                },
            ],
            json_meta: [
                [
                    {
                        key: 'charset',
                        value: 'utf-8',
                    },
                ],
            ],
            pdfTitle: 'SOS Alerts Report',
            title: 'SOS Alerts - ' + 'Report generated on: ' + moment().format('MM/DD/YYYY') + ' at ' + moment().format('HH:mm:ss'),
            filename: 'sos_alerts_' + moment().format('YYYY-MM-DD'),
        },
    },
}
