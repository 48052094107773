var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{staticClass:"modal-message",attrs:{"id":"mapModal","hide-header":true,"hide-footer":true,"size":"l"}},[_c('GmapMap',{staticStyle:{"height":"420px"},attrs:{"center":{
            lat: _vm.geolocation?.latitude ? Number(_vm.geolocation.latitude) : 0,
            lng: _vm.geolocation?.longitude ? Number(_vm.geolocation.longitude) : 0,
        },"zoom":15,"options":{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false,
        }}},[_c('GmapMarker',{attrs:{"position":{
                lat: _vm.geolocation?.latitude ? Number(_vm.geolocation.latitude) : 0,
                lng: _vm.geolocation?.longitude ? Number(_vm.geolocation.longitude) : 0,
            }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }