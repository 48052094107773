export const DOMAINS = [
    { value: 'ALL', label: 'str.log.domain.all' },
    { value: 'COMPANY_USER', label: 'str.log.domain.company_user' },
    { value: 'CUSTOMER_USER', label: 'str.log.domain.customer_user' },
    // { value: 'ALERT', label: 'str.log.domain.alert' },
    { value: 'PATROL_ACTION', label: 'str.log.domain.patrol_action' },
    // { value: 'PATROL', label: 'str.log.domain.patrol' },
    { value: 'COMPANY', label: 'str.log.domain.company' },
    { value: 'APPOINTMENT', label: 'str.log.domain.appointment' },
    { value: 'INCIDENT', label: 'str.log.domain.incident' },
    { value: 'PATROL_POINT', label: 'str.log.domain.patrol_point' },
    { value: 'SCHEDULE', label: 'str.log.domain.schedule' },
    { value: 'GUARD_GROUP', label: 'str.log.domain.guard_group' },
    { value: 'SITE_GROUP', label: 'str.log.domain.site_group' },
    { value: 'CLIENT_GROUP', label: 'str.log.domain.client_group' },
    { value: 'REPORT_SETTINGS', label: 'str.log.domain.report_settings' },
]
