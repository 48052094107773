<template>
    <div>
        <div data-custom-class="body">
            <div class="language-selector">
                <button v-on:click="changeLanguage('en')">EN</button>
                <button v-on:click="changeLanguage('pt')">PT</button>
            </div>

            <div id="privacy-policy-content-en" v-if="language === 'en'">
                <div class="content">
                    <p><strong>PRIVACY POLICY</strong></p>
                    <p><em>Last updated November 21, 2023</em></p>
                    <p>
                        This privacy notice for Grasp Sistemas e Comercio Ltda (doing business as Grasp) ("we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your
                        information when you use our services ("Services"), such as when you:
                    </p>
                    <ul>
                        <li>Visit our website at <a href="https://shieldgo.com.br/" target="_new">https://shieldgo.com.br</a>, or any website of ours that links to this privacy notice</li>
                        <li>Download and use our mobile application (ShieldGo), or any other application of ours that links to this privacy notice</li>
                        <li>Engage with us in other related ways, including any sales, marketing, or events</li>
                    </ul>
                    <p>
                        Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our
                        Services. If you still have any questions or concerns, please contact us at <a target="_new">privacy@shieldgo.com.br</a>.
                    </p>
                    <p><strong>SUMMARY OF KEY POINTS</strong></p>
                    <p>
                        This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table
                        of contents below to find the section you are looking for.
                    </p>
                    <ol>
                        <li><strong>What personal information do we process?</strong> Learn more about personal information you disclose to us.</li>
                        <li><strong>Do we process any sensitive personal information?</strong> Learn more about sensitive information we process.</li>
                        <li><strong>Do we receive any information from third parties?</strong> Learn more about information collected from other sources.</li>
                        <li><strong>How do we process your information?</strong> Learn more about how we process your information.</li>
                        <li><strong>In what situations and with which parties do we share personal information?</strong> Learn more about when and with whom we share your personal information.</li>
                        <li><strong>How do we keep your information safe?</strong> Learn more about how we keep your information safe.</li>
                        <li><strong>What are your rights?</strong> Learn more about your privacy rights.</li>
                        <li><strong>How do you exercise your rights?</strong> Learn more about how to exercise your privacy rights.</li>
                        <li><strong>Want to learn more about what we do with any information we collect?</strong> Review the privacy notice in full.</li>
                    </ol>
                    <p><strong>TABLE OF CONTENTS</strong></p>
                    <ol>
                        <li>WHAT INFORMATION DO WE COLLECT?</li>
                        <li>HOW DO WE PROCESS YOUR INFORMATION?</li>
                        <li>WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</li>
                        <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
                        <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
                        <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
                        <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
                        <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
                        <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
                        <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
                        <li>DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
                        <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
                        <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
                        <li>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</li>
                    </ol>
                    <p><strong>1. WHAT INFORMATION DO WE COLLECT?</strong></p>
                    <p><strong>Personal information you disclose to us</strong></p>
                    <p>
                        We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services,
                        when you participate in activities on the Services, or otherwise when you contact us.
                    </p>
                    <p>
                        <em>Personal Information Provided by You.</em> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and
                        the products and features you use. The personal information we collect may include the following:
                    </p>
                    <ul>
                        <li>Names</li>
                        <li>Phone numbers</li>
                        <li>Email addresses</li>
                        <li>Usernames</li>
                        <li>Passwords</li>
                        <li>Debit/credit card numbers</li>
                        <li>Billing addresses</li>
                    </ul>
                    <p><em>Sensitive Information.</em> When necessary, with your consent or as otherwise permitted by applicable law, we process the following categories of sensitive information:</p>
                    <ul>
                        <li>Social security numbers or other government identifiers</li>
                    </ul>
                    <p>
                        <em>Payment Data.</em> We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your
                        payment instrument. All payment data is stored by EBANX. You may find their privacy notice link(s) here:
                        <a href="https://shieldgo.com.br/?#/payment-privacy-policy" target="_new">https://shieldgo.com.br/?#/payment-privacy-policy</a>.
                    </p>
                    <p><em>Application Data.</em> If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:</p>
                    <ul>
                        <li>Geolocation Information</li>
                        <li>Mobile Device Access</li>
                        <li>Mobile Device Data</li>
                        <li>Push Notifications</li>
                    </ul>
                    <p>This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.</p>
                    <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>
                    <p><strong>Information automatically collected</strong></p>
                    <p>Some information &mdash; such as your Internet Protocol (IP) address and/or browser and device characteristics &mdash; is collected automatically when you visit our Services.</p>
                    <p>
                        We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact
                        information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device
                        name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation
                        of our Services, and for our internal analytics and reporting purposes.
                    </p>
                    <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
                    <p>The information we collect includes:</p>
                    <ul>
                        <li>Log and Usage Data</li>
                        <li>Device Data</li>
                        <li>Location Data</li>
                    </ul>
                    <p><strong>Information collected from other sources</strong></p>
                    <p>
                        In order to enhance our ability to provide relevant marketing, offers, and services to you and update our records, we may obtain information about you from other sources, such as public
                        databases, joint marketing partners, affiliate programs, data providers, and from other third parties.
                    </p>
                    <p><strong>2. HOW DO WE PROCESS YOUR INFORMATION?</strong></p>
                    <p>We process your personal information for a variety of reasons, depending on how you interact with our Services. These reasons include:</p>
                    <ul>
                        <li>To facilitate account creation and authentication and otherwise manage user accounts.</li>
                        <li>To deliver and facilitate delivery of services to the user.</li>
                        <li>To send administrative information to you.</li>
                        <li>To enable user-to-user communications.</li>
                        <li>To protect our Services.</li>
                        <li>To save or protect an individual's vital interest.</li>
                        <li>Geolocation and Device Information for application optimization and performance improvement.</li>
                    </ul>
                    <p><strong>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</strong></p>
                    <p>
                        We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to
                        comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.
                    </p>
                    <p>The legal bases we rely on to process your personal information include:</p>
                    <ul>
                        <li>Consent</li>
                        <li>Performance of a Contract</li>
                        <li>Legitimate Interests</li>
                        <li>Legal Obligations</li>
                        <li>Vital Interests</li>
                    </ul>
                    <p><strong>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</strong></p>
                    <p>We may need to share your personal information in specific situations described in this section and/or with the following third parties:</p>
                    <ul>
                        <li>
                            With service providers: To facilitate our services; to provide services on our behalf; to perform service-related services; or to assist us in analyzing how our services are used.
                        </li>
                        <li>
                            With other users: When you share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly
                            distributed outside.
                        </li>
                        <li>With affiliates: To our affiliates for the purposes described in this Privacy Policy.</li>
                        <li>With business partners: With trusted business partners who may use your personal information to contact you about related products and services.</li>
                        <li>With other third parties: With other third parties for purposes for which we have obtained your consent, and as otherwise described in this Privacy Policy.</li>
                    </ul>
                    <p><strong>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</strong></p>
                    <p>We use cookies and similar tracking technologies to collect and use personal information about you, including to serve interest-based advertising.</p>
                    <p><strong>6. HOW LONG DO WE KEEP YOUR INFORMATION?</strong></p>
                    <p>
                        We retain your personal information for as long as is necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.
                    </p>
                    <p><strong>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong></p>
                    <p>We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process.</p>
                    <p><strong>8. DO WE COLLECT INFORMATION FROM MINORS?</strong></p>
                    <p>
                        We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of
                        such a minor and consent to such minor dependent&rsquo;s use of the Services.
                    </p>
                    <p><strong>9. WHAT ARE YOUR PRIVACY RIGHTS?</strong></p>
                    <p>
                        In some regions, you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to
                        request rectification or erasure, (iii) to restrict the processing of your personal information, and (iv) if applicable, to data portability.
                    </p>
                    <p><strong>10. CONTROLS FOR DO-NOT-TRACK FEATURES</strong></p>
                    <p>
                        Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to
                        have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As
                        such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online.
                    </p>
                    <p><strong>11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</strong></p>
                    <p>California Privacy Rights.</p>
                    <ul>
                        <li>
                            If you are under 18 years of age, reside in California, and have a registered account with the Services, you have the right to request removal of unwanted data that you publicly post
                            on the Services.
                        </li>
                        <li>
                            To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you
                            reside in California.
                        </li>
                        <li>We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from our systems.</li>
                    </ul>
                    <p><strong>12. DO WE MAKE UPDATES TO THIS NOTICE?</strong></p>
                    <p>
                        We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is
                        accessible.
                    </p>
                    <p><strong>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong></p>
                    <p>
                        If you have questions or comments about this notice, you may contact our Data Protection Officer (DPO), [DPO Name], by email at <a target="_new">privacy@shieldgo.com.br</a> or by post
                        to:
                    </p>
                    <p>Grasp Sistemas e Comercio Ltda Attn: Data Protection Officer Rua Rui Barbosa, 123 Sao Paulo, SP 01026-000 Brazil</p>
                    <p><strong>14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</strong></p>
                    <p>
                        Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request
                        to review, update, or delete your personal information, please fill out and submit a data subject access request.
                    </p>
                </div>
            </div>

            <div v-else id="privacy-policy-content-pt">
                <div class="content">
                    <!-- ####### HEY, I AM THE SOURCE EDITOR! #########-->
                    <div
                        class="min-h-[20px] text-message flex flex-col items-start gap-3 whitespace-pre-wrap break-words [.text-message+&amp;]:mt-5 overflow-x-auto"
                        data-message-author-role="assistant"
                        data-message-id="f21bdad5-e943-4448-9209-61994b9dd93c"
                    >
                        <div class="markdown prose w-full break-words dark:prose-invert dark">
                            <div
                                class="min-h-[20px] text-message flex flex-col items-start gap-3 whitespace-pre-wrap break-words [.text-message+&amp;]:mt-5 overflow-x-auto"
                                data-message-author-role="assistant"
                                data-message-id="f21bdad5-e943-4448-9209-61994b9dd93c"
                            >
                                <div class="markdown prose w-full break-words dark:prose-invert dark">
                                    <div
                                        class="min-h-[20px] text-message flex flex-col items-start gap-3 whitespace-pre-wrap break-words [.text-message+&amp;]:mt-5 overflow-x-auto"
                                        data-message-author-role="assistant"
                                        data-message-id="f21bdad5-e943-4448-9209-61994b9dd93c"
                                    >
                                        <div class="markdown prose w-full break-words dark:prose-invert dark">
                                            <p><strong>POL&Iacute;TICA DE PRIVACIDADE</strong></p>
                                            <p>&Uacute;ltima atualiza&ccedil;&atilde;o em 21 de novembro de 2023</p>
                                            <p>
                                                Este aviso de privacidade da Grasp Sistemas e Com&eacute;rcio Ltda (fazendo neg&oacute;cios como Grasp) ("n&oacute;s", "nos" ou "nosso") descreve como e por que
                                                podemos coletar, armazenar, usar e/ou compartilhar ("processar") suas informa&ccedil;&otilde;es quando voc&ecirc; usa nossos servi&ccedil;os ("Servi&ccedil;os"),
                                                como quando voc&ecirc;: Visita nosso site em <a href="https://shieldgo.com.br/" target="_new">https://shieldgo.com.br</a>, ou qualquer site nosso que se vincule a
                                                este aviso de privacidade Baixa e usa nosso aplicativo m&oacute;vel (ShieldGo), ou qualquer outro aplicativo nosso que se vincule a este aviso de privacidade
                                                Interage conosco de outras maneiras relacionadas, incluindo quaisquer vendas, marketing ou eventos Tem d&uacute;vidas ou preocupa&ccedil;&otilde;es? Ler este
                                                aviso de privacidade ajudar&aacute; voc&ecirc; a entender seus direitos e op&ccedil;&otilde;es de privacidade. Se voc&ecirc; n&atilde;o concorda com nossas
                                                pol&iacute;ticas e pr&aacute;ticas, por favor, n&atilde;o use nossos Servi&ccedil;os. Se ainda tiver alguma d&uacute;vida ou preocupa&ccedil;&atilde;o, entre em
                                                contato conosco em <a target="_new">privacy@shieldgo.com.br</a>.
                                            </p>
                                            <p><strong>RESUMO DOS PONTOS CHAVE</strong></p>
                                            <p>
                                                Este resumo fornece pontos chave do nosso aviso de privacidade, mas voc&ecirc; pode encontrar mais detalhes sobre qualquer um desses t&oacute;picos clicando no
                                                link a seguir a cada ponto chave ou usando nosso &iacute;ndice abaixo para encontrar a se&ccedil;&atilde;o que est&aacute; procurando.
                                            </p>
                                            <p><strong>1. QUE INFORMA&Ccedil;&Otilde;ES COLETAMOS?</strong> 2. COMO PROCESSAMOS SUAS INFORMA&Ccedil;&Otilde;ES?</p>
                                            <ol start="3">
                                                <li>EM QUAIS BASES LEGAIS NOS APOIAMOS PARA PROCESSAR SUAS INFORMA&Ccedil;&Otilde;ES PESSOAIS?</li>
                                                <li>QUANDO E COM QUEM COMPARTILHAMOS SUAS INFORMA&Ccedil;&Otilde;ES PESSOAIS?</li>
                                                <li>USAMOS COOKIES E OUTRAS TECNOLOGIAS DE RASTREAMENTO?</li>
                                                <li>POR QUANTO TEMPO GUARDAMOS SUAS INFORMA&Ccedil;&Otilde;ES?</li>
                                                <li>COMO MANT&Eacute;MOS SUAS INFORMA&Ccedil;&Otilde;ES SEGURAS?</li>
                                                <li>COLETAMOS INFORMA&Ccedil;&Otilde;ES DE MENORES?</li>
                                                <li>QUAIS S&Atilde;O SEUS DIREITOS DE PRIVACIDADE?</li>
                                                <li>CONTROLES PARA RECURSOS DE N&Atilde;O RASTREAMENTO</li>
                                                <li>RESIDENTES DOS ESTADOS UNIDOS T&Ecirc;M DIREITOS DE PRIVACIDADE ESPEC&Iacute;FICOS?</li>
                                                <li>FAZEMOS ATUALIZA&Ccedil;&Otilde;ES NESTE AVISO?</li>
                                                <li>COMO VOC&Ecirc; PODE ENTRAR EM CONTATO CONOSCO SOBRE ESTE AVISO?</li>
                                                <li>COMO VOC&Ecirc; PODE REVISAR, ATUALIZAR OU EXCLUIR OS DADOS QUE COLETAMOS DE VOC&Ecirc;?</li>
                                            </ol>
                                            <hr />
                                            <p>Devido &agrave; extens&atilde;o do texto, essa &eacute; a primeira parte da tradu&ccedil;&atilde;o. Vou continuar traduzindo o restante em sequ&ecirc;ncia.</p>
                                        </div>
                                    </div>
                                    <div
                                        class="text-message flex flex-col items-start gap-3 whitespace-pre-wrap break-words [.text-message+&amp;]:mt-5 overflow-x-auto"
                                        data-message-author-role="assistant"
                                        data-message-id="8bba1985-b007-4a60-873c-d19397e1e9d2"
                                    >
                                        <div class="markdown prose w-full break-words dark:prose-invert dark">
                                            <p>Continuando a tradu&ccedil;&atilde;o:</p>
                                            <hr />
                                            <p><strong>1. QUE INFORMA&Ccedil;&Otilde;ES COLETAMOS?</strong></p>
                                            <p>Informa&ccedil;&otilde;es pessoais que voc&ecirc; nos fornece</p>
                                            <p>Em resumo: Coletamos informa&ccedil;&otilde;es pessoais que voc&ecirc; nos fornece.</p>
                                            <p>
                                                Coletamos informa&ccedil;&otilde;es pessoais que voc&ecirc; fornece voluntariamente quando se registra nos Servi&ccedil;os, expressa interesse em obter
                                                informa&ccedil;&otilde;es sobre n&oacute;s ou nossos produtos e Servi&ccedil;os, quando participa de atividades nos Servi&ccedil;os ou de outra forma quando entra
                                                em contato conosco.
                                            </p>
                                            <p>
                                                <strong>Informa&ccedil;&otilde;es Pessoais Fornecidas por Voc&ecirc;.</strong> As informa&ccedil;&otilde;es pessoais que coletamos dependem do contexto de suas
                                                intera&ccedil;&otilde;es conosco e com os Servi&ccedil;os, as escolhas que voc&ecirc; faz e os produtos e recursos que voc&ecirc; usa. As
                                                informa&ccedil;&otilde;es pessoais que coletamos podem incluir o seguinte:
                                            </p>
                                            <ul>
                                                <li>nomes</li>
                                                <li>n&uacute;meros de telefone</li>
                                                <li>endere&ccedil;os de e-mail</li>
                                                <li>nomes de usu&aacute;rio</li>
                                                <li>senhas</li>
                                                <li>n&uacute;meros de cart&otilde;es de d&eacute;bito/cr&eacute;dito</li>
                                                <li>endere&ccedil;os de cobran&ccedil;a</li>
                                            </ul>
                                            <p>
                                                <strong>Informa&ccedil;&otilde;es Sens&iacute;veis.</strong> Quando necess&aacute;rio, com seu consentimento ou conforme permitido pela lei aplic&aacute;vel,
                                                processamos as seguintes categorias de informa&ccedil;&otilde;es sens&iacute;veis:
                                            </p>
                                            <ul>
                                                <li>n&uacute;meros de seguran&ccedil;a social ou outros identificadores governamentais</li>
                                            </ul>
                                            <p>
                                                <strong>Dados de Pagamento.</strong> Podemos coletar dados necess&aacute;rios para processar seu pagamento se voc&ecirc; fizer compras, como o n&uacute;mero do
                                                seu instrumento de pagamento e o c&oacute;digo de seguran&ccedil;a associado ao seu instrumento de pagamento. Todos os dados de pagamento s&atilde;o armazenados
                                                pelo EBANX. Voc&ecirc; pode encontrar seus avisos de privacidade aqui:
                                                <a href="https://shieldgo.com.br/?#/payment-privacy-policy" target="_new">https://shieldgo.com.br/?#/payment-privacy-policy</a>.
                                            </p>
                                            <p>
                                                <strong>Dados de Aplicativos.</strong> Se voc&ecirc; usar nossos aplicativos, tamb&eacute;m podemos coletar as seguintes informa&ccedil;&otilde;es, caso
                                                voc&ecirc; escolha nos fornecer acesso ou permiss&atilde;o:
                                            </p>
                                            <ul>
                                                <li>
                                                    Informa&ccedil;&otilde;es de Geolocaliza&ccedil;&atilde;o. Podemos solicitar acesso ou permiss&atilde;o para rastrear informa&ccedil;&otilde;es baseadas na
                                                    localiza&ccedil;&atilde;o do seu dispositivo m&oacute;vel, continuamente ou enquanto voc&ecirc; estiver usando nossos aplicativos m&oacute;veis, para fornecer
                                                    certos servi&ccedil;os baseados na localiza&ccedil;&atilde;o.
                                                </li>
                                                <li>
                                                    Acesso ao Dispositivo M&oacute;vel. Podemos solicitar acesso ou permiss&atilde;o para certos recursos do seu dispositivo m&oacute;vel, incluindo
                                                    armazenamento, microfone, c&acirc;mera, sensores, bluetooth e outros recursos.
                                                </li>
                                                <li>
                                                    Dados do Dispositivo M&oacute;vel. Coletamos automaticamente informa&ccedil;&otilde;es sobre o dispositivo (como o ID do dispositivo m&oacute;vel, modelo e
                                                    fabricante), sistema operacional, informa&ccedil;&otilde;es de vers&atilde;o e configura&ccedil;&atilde;o do sistema, identifica&ccedil;&atilde;o do
                                                    dispositivo e do aplicativo, tipo e vers&atilde;o do navegador, modelo de hardware, provedor de servi&ccedil;os de Internet e/ou operadora m&oacute;vel e
                                                    endere&ccedil;o de Protocolo de Internet (IP).
                                                </li>
                                            </ul>
                                            <p>
                                                <strong>Notifica&ccedil;&otilde;es Push.</strong> Podemos solicitar para enviar notifica&ccedil;&otilde;es push sobre sua conta ou certos recursos dos
                                                aplicativos. Se desejar optar por n&atilde;o receber esses tipos de comunica&ccedil;&otilde;es, voc&ecirc; pode desativ&aacute;-las nas
                                                configura&ccedil;&otilde;es do seu dispositivo.
                                            </p>
                                            <p>
                                                Essas informa&ccedil;&otilde;es s&atilde;o principalmente necess&aacute;rias para manter a seguran&ccedil;a e opera&ccedil;&atilde;o de nossos aplicativos, para
                                                solu&ccedil;&atilde;o de problemas e para nossas an&aacute;lises internas e prop&oacute;sitos de relat&oacute;rios.
                                            </p>
                                            <p>
                                                Todas as informa&ccedil;&otilde;es pessoais que voc&ecirc; nos fornece devem ser verdadeiras, completas e precisas, e voc&ecirc; deve nos notificar sobre
                                                quaisquer altera&ccedil;&otilde;es nessas informa&ccedil;&otilde;es pessoais.
                                            </p>
                                            <p>Informa&ccedil;&otilde;es coletadas automaticamente</p>
                                            <p>
                                                Em resumo: Algumas informa&ccedil;&otilde;es &mdash; como seu endere&ccedil;o de Protocolo de Internet (IP) e/ou caracter&iacute;sticas do navegador e dispositivo
                                                &mdash; s&atilde;o coletadas automaticamente quando voc&ecirc; visita nossos Servi&ccedil;os.
                                            </p>
                                            <p>
                                                Coletamos automaticamente certas informa&ccedil;&otilde;es quando voc&ecirc; visita, usa ou navega nos Servi&ccedil;os. Essas informa&ccedil;&otilde;es n&atilde;o
                                                revelam sua identidade espec&iacute;fica (como seu nome ou informa&ccedil;&otilde;es de contato), mas podem incluir informa&ccedil;&otilde;es sobre o dispositivo
                                                e uso, como seu endere&ccedil;o IP, caracter&iacute;sticas do navegador e do dispositivo, sistema operacional, prefer&ecirc;ncias de idioma, URLs de
                                                refer&ecirc;ncia, nome do dispositivo, pa&iacute;s, localiza&ccedil;&atilde;o, informa&ccedil;&otilde;es sobre como e quando voc&ecirc; usa nossos Servi&ccedil;os
                                                e outras informa&ccedil;&otilde;es t&eacute;cnicas. Essas informa&ccedil;&otilde;es s&atilde;o principalmente necess&aacute;rias para manter a seguran&ccedil;a e
                                                opera&ccedil;&atilde;o de nossos Servi&ccedil;os e para nossas an&aacute;lises internas e prop&oacute;sitos de relat&oacute;rios.
                                            </p>
                                            <p>Como muitos neg&oacute;cios, tamb&eacute;m coletamos informa&ccedil;&otilde;es por meio de cookies e tecnologias similares.</p>
                                            <p>As informa&ccedil;&otilde;es que coletamos incluem:</p>
                                            <ul>
                                                <li>
                                                    Dados de Log e Uso. Dados de log e uso s&atilde;o informa&ccedil;&otilde;es relacionadas ao servi&ccedil;o, diagn&oacute;stico, uso e desempenho que nossos
                                                    servidores coletam automaticamente quando voc&ecirc; acessa ou usa nossos Servi&ccedil;os e que registramos em arquivos de log. Dependendo de como voc&ecirc;
                                                    interage conosco, esses dados de log podem incluir seu endere&ccedil;o IP, informa&ccedil;&otilde;es do dispositivo, tipo de navegador e
                                                    configura&ccedil;&otilde;es, e informa&ccedil;&otilde;es sobre sua atividade nos Servi&ccedil;os (como os carimbos de data/hora associados ao seu uso,
                                                    p&aacute;ginas e arquivos visualizados, pesquisas e outras a&ccedil;&otilde;es que voc&ecirc; realiza, como quais recursos voc&ecirc; usa),
                                                    informa&ccedil;&otilde;es sobre eventos do dispositivo (como atividade do sistema, relat&oacute;rios de erro (&agrave;s vezes chamados de "despejos de
                                                    falhas") e configura&ccedil;&otilde;es de hardware).
                                                </li>
                                                <li>
                                                    Dados do Dispositivo. Coletamos dados do dispositivo, como informa&ccedil;&otilde;es sobre seu computador, telefone, tablet ou outro dispositivo que
                                                    voc&ecirc; usa para acessar os Servi&ccedil;os. Dependendo do dispositivo usado, esses dados do dispositivo podem incluir informa&ccedil;&otilde;es como seu
                                                    endere&ccedil;o IP (ou servidor proxy), n&uacute;meros de identifica&ccedil;&atilde;o do dispositivo e do aplicativo, localiza&ccedil;&atilde;o, tipo de
                                                    navegador, modelo de hardware, provedor de servi&ccedil;os de Internet e/ou operadora m&oacute;vel, sistema operacional e informa&ccedil;&otilde;es de
                                                    configura&ccedil;&atilde;o do sistema.
                                                </li>
                                                <li>
                                                    Dados de Localiza&ccedil;&atilde;o. Coletamos dados de localiza&ccedil;&atilde;o, como informa&ccedil;&otilde;es sobre a localiza&ccedil;&atilde;o do seu
                                                    dispositivo, que podem ser precisas ou imprecisas. A quantidade de informa&ccedil;&otilde;es que coletamos depende do tipo e configura&ccedil;&otilde;es do
                                                    dispositivo que voc&ecirc; usa para acessar os Servi&ccedil;os. Por exemplo, podemos usar GPS e outras tecnologias para coletar dados de
                                                    geolocaliza&ccedil;&atilde;o que nos informem sua localiza&ccedil;&atilde;o atual (com base no seu endere&ccedil;o IP).
                                                </li>
                                            </ul>
                                            <p>Informa&ccedil;&otilde;es coletadas de outras fontes</p>
                                            <p>Em resumo: Podemos coletar dados limitados de bancos de dados p&uacute;blicos, parceiros de marketing e outras fontes externas.</p>
                                            <p>
                                                Para aprimorar nossa capacidade de fornecer marketing relevante, ofertas e servi&ccedil;os a voc&ecirc; e atualizar nossos registros, podemos obter
                                                informa&ccedil;&otilde;es sobre voc&ecirc; de outras fontes, como bancos de dados p&uacute;blicos, parceiros de marketing, programas de afiliados, provedores de
                                                dados e de outras terceiras partes. Essas informa&ccedil;&otilde;es incluem endere&ccedil;os postais, t&iacute;tulos de trabalho, endere&ccedil;os de e-mail,
                                                n&uacute;meros de telefone, dados de inten&ccedil;&atilde;o (ou dados de comportamento do usu&aacute;rio), endere&ccedil;os de Protocolo de Internet (IP), perfis
                                                de m&iacute;dia social, URLs de m&iacute;dia social e perfis personalizados, para fins de publicidade direcionada e promo&ccedil;&atilde;o de eventos.
                                            </p>
                                            <hr />
                                            <p>Continuarei com a tradu&ccedil;&atilde;o na pr&oacute;xima mensagem.</p>
                                        </div>
                                    </div>
                                    <div
                                        class="text-message flex flex-col items-start gap-3 whitespace-pre-wrap break-words [.text-message+&amp;]:mt-5 overflow-x-auto"
                                        data-message-author-role="assistant"
                                        data-message-id="c7596795-4d37-45de-b827-a38ef5782fd5"
                                    >
                                        <div class="markdown prose w-full break-words dark:prose-invert dark">
                                            <p>Continuando a tradu&ccedil;&atilde;o:</p>
                                            <hr />
                                            <p><strong>2. COMO PROCESSAMOS SUAS INFORMA&Ccedil;&Otilde;ES?</strong></p>
                                            <p>
                                                Em resumo: Processamos suas informa&ccedil;&otilde;es para fornecer, melhorar e administrar nossos Servi&ccedil;os, comunicar com voc&ecirc;, para
                                                seguran&ccedil;a e preven&ccedil;&atilde;o de fraudes e para cumprir a lei. Tamb&eacute;m podemos processar suas informa&ccedil;&otilde;es para outros
                                                prop&oacute;sitos com seu consentimento.
                                            </p>
                                            <p>
                                                Processamos suas informa&ccedil;&otilde;es pessoais por uma variedade de raz&otilde;es, dependendo de como voc&ecirc; interage com nossos Servi&ccedil;os,
                                                incluindo:
                                            </p>
                                            <ul>
                                                <li>
                                                    Para facilitar a cria&ccedil;&atilde;o de conta e autentica&ccedil;&atilde;o e de outra forma gerenciar contas de usu&aacute;rios. Podemos processar suas
                                                    informa&ccedil;&otilde;es para que voc&ecirc; possa criar e fazer login em sua conta, bem como manter sua conta em funcionamento.
                                                </li>
                                                <li>
                                                    Para entregar e facilitar a entrega de servi&ccedil;os ao usu&aacute;rio. Podemos processar suas informa&ccedil;&otilde;es para fornecer o servi&ccedil;o
                                                    solicitado.
                                                </li>
                                                <li>
                                                    Para enviar informa&ccedil;&otilde;es administrativas para voc&ecirc;. Podemos processar suas informa&ccedil;&otilde;es para enviar detalhes sobre nossos
                                                    produtos e servi&ccedil;os, mudan&ccedil;as em nossos termos e pol&iacute;ticas, e outras informa&ccedil;&otilde;es semelhantes.
                                                </li>
                                            </ul>
                                            <p><strong>3. QUAIS BASES LEGAIS NOS APOIAMOS PARA PROCESSAR SUAS INFORMA&Ccedil;&Otilde;ES PESSOAIS?</strong></p>
                                            <p>
                                                Em resumo: S&oacute; processamos suas informa&ccedil;&otilde;es pessoais quando acreditamos que &eacute; necess&aacute;rio e temos uma raz&atilde;o legal
                                                v&aacute;lida (ou seja, base legal) para faz&ecirc;-lo sob a lei aplic&aacute;vel, como com seu consentimento, para cumprir leis, para fornecer-lhe
                                                servi&ccedil;os para entrar em ou cumprir nossas obriga&ccedil;&otilde;es contratuais, para proteger seus direitos ou para cumprir nossos interesses comerciais
                                                leg&iacute;timos.
                                            </p>
                                            <p><strong>4. QUANDO E COM QUEM COMPARTILHAMOS SUAS INFORMA&Ccedil;&Otilde;ES PESSOAIS?</strong></p>
                                            <p>
                                                Em resumo: Podemos compartilhar informa&ccedil;&otilde;es em situa&ccedil;&otilde;es espec&iacute;ficas descritas nesta se&ccedil;&atilde;o e/ou com os seguintes
                                                terceiros.
                                            </p>
                                            <p><strong>5. USAMOS COOKIES E OUTRAS TECNOLOGIAS DE RASTREAMENTO?</strong></p>
                                            <p>Em resumo: Podemos usar cookies e outras tecnologias de rastreamento para coletar e armazenar suas informa&ccedil;&otilde;es.</p>
                                            <p><strong>6. POR QUANTO TEMPO GUARDAMOS SUAS INFORMA&Ccedil;&Otilde;ES?</strong></p>
                                            <p>
                                                Em resumo: Mantemos suas informa&ccedil;&otilde;es pelo tempo necess&aacute;rio para cumprir os prop&oacute;sitos delineados neste aviso de privacidade, a menos
                                                que seja necess&aacute;rio um per&iacute;odo de reten&ccedil;&atilde;o mais longo conforme exigido ou permitido por lei.
                                            </p>
                                            <p><strong>7. COMO MANT&Eacute;MOS SUAS INFORMA&Ccedil;&Otilde;ES SEGURAS?</strong></p>
                                            <p>
                                                Em resumo: Nosso objetivo &eacute; proteger suas informa&ccedil;&otilde;es pessoais por meio de um sistema de medidas de seguran&ccedil;a organizacionais e
                                                t&eacute;cnicas.
                                            </p>
                                            <p><strong>8. COLETAMOS INFORMA&Ccedil;&Otilde;ES DE MENORES?</strong></p>
                                            <p>Em resumo: N&atilde;o coletamos dados de menores de 18 anos de idade.</p>
                                            <p><strong>9. QUAIS S&Atilde;O SEUS DIREITOS DE PRIVACIDADE?</strong></p>
                                            <p>
                                                Em resumo: Em algumas regi&otilde;es, como a &Aacute;rea Econ&ocirc;mica Europeia (EEA), Reino Unido (UK), Su&iacute;&ccedil;a e Canad&aacute;, voc&ecirc; tem
                                                direitos que permitem maior acesso e controle sobre suas informa&ccedil;&otilde;es pessoais.
                                            </p>
                                            <p><strong>10. CONTROLES PARA RECURSOS DE N&Atilde;O RASTREAMENTO</strong></p>
                                            <p>
                                                A maioria dos navegadores da web e alguns sistemas operacionais m&oacute;veis e aplicativos m&oacute;veis incluem um recurso ou configura&ccedil;&atilde;o de
                                                N&atilde;o Rastrear ("DNT") que voc&ecirc; pode ativar para sinalizar sua prefer&ecirc;ncia de privacidade para n&atilde;o ter dados sobre suas atividades de
                                                navega&ccedil;&atilde;o online monitoradas e coletadas.
                                            </p>
                                            <p><strong>11. RESIDENTES DOS ESTADOS UNIDOS T&Ecirc;M DIREITOS DE PRIVACIDADE ESPEC&Iacute;FICOS?</strong></p>
                                            <p>
                                                Em resumo: Se voc&ecirc; for residente dos Estados Unidos, tem direitos espec&iacute;ficos em rela&ccedil;&atilde;o ao acesso &agrave;s suas
                                                informa&ccedil;&otilde;es pessoais.
                                            </p>
                                            <p><strong>12. FAZEMOS ATUALIZA&Ccedil;&Otilde;ES NESTE AVISO?</strong></p>
                                            <p>Em resumo: Sim, atualizaremos este aviso conforme necess&aacute;rio para permanecer em conformidade com as leis relevantes.</p>
                                            <p><strong>13. COMO VOC&Ecirc; PODE ENTRAR EM CONTATO CONOSCO SOBRE ESTE AVISO?</strong></p>
                                            <p>
                                                Se tiver d&uacute;vidas ou coment&aacute;rios sobre este aviso, voc&ecirc; pode entrar em contato com nosso Oficial de Prote&ccedil;&atilde;o de Dados (DPO),
                                                Thiago Teixeira Simon, por e-mail em <a target="_new">thiagosimon@grasp.com.br</a>, por telefone no +55 11 972194501, ou entrar em contato conosco por correio em:
                                            </p>
                                            <p>
                                                Grasp Sistemas e Com&eacute;rcio Ltda Thiago Teixeira Simon Rua Gabriele D'Annunzio, 132 - Campo Belo, S&atilde;o Paulo - SP, 04619-000, Brasil S&atilde;o Paulo,
                                                S&atilde;o Paulo 04619-000 Brasil
                                            </p>
                                            <p><strong>14. COMO VOC&Ecirc; PODE REVISAR, ATUALIZAR OU EXCLUIR OS DADOS QUE COLETAMOS DE VOC&Ecirc;?</strong></p>
                                            <p>
                                                Com base nas leis aplicáveis do seu país, você pode ter o direito de solicitar o acesso às informações pessoais que coletamos de você, alterar essas informações
                                                ou excluí-las. Para solicitar a revisão, atualização ou exclusão das suas informações pessoais, por favor, preencha e envie um pedido de acesso do titular dos
                                                dados.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img src="../../assets/images/logo-inverse.png" alt="Privacy Policy" class="img-fluid center-block d-block mx-auto" style="max-width: 200px; margin-top: 20px; margin-bottom: 20px" />
    </div>
</template>

<script>
import AppOptions from '../../config/AppOptions.vue'

export default {
    name: 'PrivacyPolicy',
    created() {
        AppOptions.appEmpty = true
    },
    data() {
        return {
            language: 'en',
        }
    },
    methods: {
        changeLanguage(language) {
            this.language = language
        },
    },
}
</script>

<style>
[data-custom-class='body'],
[data-custom-class='body'] * {
    background: white !important;
}
[data-custom-class='title'],
[data-custom-class='title'] * {
    font-family: Arial !important;
    font-size: 26px !important;
    color: #000000 !important;
}
[data-custom-class='subtitle'],
[data-custom-class='subtitle'] * {
    font-family: Arial !important;
    color: #595959 !important;
    font-size: 14px !important;
}
[data-custom-class='heading_1'],
[data-custom-class='heading_1'] * {
    font-family: Arial !important;
    font-size: 19px !important;
    color: #000000 !important;
}
[data-custom-class='heading_2'],
[data-custom-class='heading_2'] * {
    font-family: Arial !important;
    font-size: 17px !important;
    color: #000000 !important;
}
[data-custom-class='body_text'],
[data-custom-class='body_text'] * {
    color: #595959 !important;
    font-size: 14px !important;
    font-family: Arial !important;
}
[data-custom-class='link'],
[data-custom-class='link'] * {
    color: #3030f1 !important;
    font-size: 14px !important;
    font-family: Arial !important;
    word-break: break-word !important;
}
.body {
    background-color: #ffffff;
    opacity: 1;
}

.content {
    padding: 3% 10% 5% 10%;
}
.language-selector {
    display: flex;
    justify-content: flex-end;
    margin-right: 10%;
    padding-top: 10px;
}
</style>
