import moment from 'moment'

export const JSON_FIELDS_CSV = {
    incident: {
        pt: {
            json_fields: {
                Vigilante: 'vigilant',
                Data: 'date',
                Incidentes: 'incidentsCsv',
                'Status de atendimento': 'attendanceStatus',
                'Data de abertura': 'openedDate',
                'Data de fechamento': 'closedDate',
                'Marca do dispositivo': 'deviceInfoBrand',
                'Modelo do dispositivo': 'deviceInfoModel',
                'Atendimento realizado': 'isAttendance',
                Latitude: 'latitude',
                Longitude: 'longitude',
                Operador: 'operator',
                Foto: 'photoURL',
                Assinatura: 'signatureURL',
                Cliente: 'client',
                Local: 'site',
            },
            json_data: [
                {
                    vigilant: '',
                    date: '',
                    incidentsCsv: '',
                    attendanceStatus: '',
                    openedDate: '',
                    closedDate: '',
                    deviceInfoBrand: '',
                    deviceInfoModel: '',
                    isAttendance: '',
                    latitude: '',
                    longitude: '',
                    operator: '',
                    photoURL: '',
                    signatureURL: '',
                    client: '',
                    site: '',
                },
            ],
            json_meta: [
                [
                    {
                        key: 'charset',
                        value: 'utf-8',
                    },
                ],
            ],
            pdfTitle: 'Relatório de Incidentes',
            title: 'Relatório de incidentes - ' + 'Gerado em: ' + moment().format('DD/MM/YYYY') + ' às ' + moment().format('HH:mm:ss'),
            filename: 'incidentes_' + moment().format('DD/MM/YYYY'),
        },
        en: {
            json_fields: {
                Vigilant: 'vigilant',
                Date: 'date',
                Incidents: 'incidentsCsv',
                'Attendance status': 'attendanceStatus',
                'Opened date': 'openedDate',
                'Closed date': 'closedDate',
                'Device brand': 'deviceInfoBrand',
                'Device model': 'deviceInfoModel',
                'Attendance performed': 'isAttendance',
                Latitude: 'latitude',
                Longitude: 'longitude',
                Operator: 'operator',
                Photo: 'photoURL',
                Signature: 'signatureURL',
                Client: 'client',
                Site: 'site',
            },
            json_data: [
                {
                    vigilant: '',
                    date: '',
                    incidentsCsv: '',
                    attendanceStatus: '',
                    openedDate: '',
                    closedDate: '',
                    deviceInfoBrand: '',
                    deviceInfoModel: '',
                    isAttendance: '',
                    latitude: '',
                    longitude: '',
                    operator: '',
                    photoURL: '',
                    signatureURL: '',
                    client: '',
                    site: '',
                },
            ],
            json_meta: [
                [
                    {
                        key: 'charset',
                        value: 'utf-8',
                    },
                ],
            ],
            pdfTitle: 'Incidents Report',
            title: 'Incidents Report - ' + 'Generated on: ' + moment().format('DD/MM/YYYY') + ' at ' + moment().format('HH:mm:ss'),
            filename: 'incidents_' + moment().format('YYYY-MM-DD'),
        },
    },
}
