export const site = {
    name: '',
    logoURL: '',
    account: '',
    client: '',
    primaryPhone: '',
    owner: '',
    address: {
        cep: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
        ibge: '',
        gia: '',
        name: '',
        lat: '',
        lng: '',
    },
    type: 'SITE',
    status: 'ACTIVE',
    enableFreePatrol: false,
}
