export const schedule = {
    name: '',
    guardGroup: '',
    account: '',
    client: '',
    site: '',
    frequency: '',
    category: '',
    frequencyMonth: {
        day: '',
    },
    frequencyYear: {
        month: '',
        day: '',
    },
    points: [],
    weeklyDays: [],
    beginDate: null,
    endDate: null,
    beginHour: '',
    endHour: '',
    sendAlert: false,
    notifyVigilants: true,
    timeSlot: 15,
    type: 'FREE-PROGRAM',
    status: 'ACTIVE',
}