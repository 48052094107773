export const PDF_HEADER = {
    pt: [
        { datakey: 'client', title: 'Cliente' },
        { datakey: 'site', title: 'Local' },
        { datakey: 'vigilant', title: 'Vigilante' },
        { datakey: 'date', title: 'Data' },
        { datakey: 'deviceInfo', title: 'Dispositivo' },
        { datakey: 'isAttendance', title: 'Atendido' },
        // { datakey: 'attendanceStatus', title: 'Status' },
        // { datakey: 'operator', title: 'Operador' },
        // { datakey: 'openedDate', title: 'Abertura' },
        // { datakey: 'closedDate', title: 'Fechamento' },
    ],
    en: [
        { datakey: 'client', title: 'Client' },
        { datakey: 'site', title: 'Site' },
        { datakey: 'vigilant', title: 'Vigilant' },
        { datakey: 'date', title: 'Date' },
        { datakey: 'deviceInfo', title: 'Device' },
        { datakey: 'isAttendance', title: 'Attendance' },
        // { datakey: 'attendanceStatus', title: 'Status' },
        // { datakey: 'operator', title: 'Operator' },
        // { datakey: 'openedDate', title: 'Opened' },
        // { datakey: 'closedDate', title: 'Closed' },
    ],
}
