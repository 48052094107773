<template>
    <div class="col-md-12">
        <div class="card bg-gray-800 text-white">
            <div class="card-body">
                <div class="mb-3 text-gray-500 text-center">
                    <b>{{ $t('str.msg.analysis.patrols.complete.vs.incomplete').toUpperCase() }}</b>
                </div>
            </div>
            <div class="card-body pe-3 ps-0 py-0">
                <apexchart :options="chartOptions" :series="chartSeries" id="statusChart" ref="statusChart" type="pie" height="300"></apexchart>
            </div>
        </div>
    </div>
</template>

<script>
import pt from 'apexcharts/dist/locales/pt-br.json'
import en from 'apexcharts/dist/locales/en.json'

export default {
    name: 'PatrolCompletionStatusPieChart',
    props: ['data', 'locale'],
    watch: {
        data: {
            immediate: true,
            handler(newData) {
                if (newData) {
                    this.processData(newData)
                }
            },
        },
        locale: {
            immediate: true,
            handler(newLocale) {
                this.updateChartLocale(newLocale)
            },
        },
    },
    data() {
        return {
            chartSeries: [],
            chartOptions: {
                colors: ['#32a932', '#db1d04'],
                chart: {
                    type: 'pie',
                    locales: [pt, en],
                    defaultLocale: this.locale || 'pt-br',
                },
                labels: [this.$t('str.complete'), this.$t('str.incomplete')],
                legend: {
                    position: 'bottom',
                    labels: {
                        colors: '#fff',
                    },
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return `${val}`
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val, opts) {
                        return `${val.toFixed(2)}% (${opts.w.globals.series[opts.seriesIndex]})`
                    },
                },
                stroke: {
                    width: 1,
                },
            },
        }
    },
    mounted() {
        this.processData(this.data)
    },
    methods: {
        processData(data) {
            const complete = data?.complete || 0
            const incomplete = data?.incomplete || 0

            this.chartSeries = [complete, incomplete]
            this.updateChartLocale(this.locale)
        },
        updateChartLocale(newLocale) {
            const newLabels = [this.$t('str.complete'), this.$t('str.incomplete')]
            this.chartOptions.labels = newLabels
            this.chartOptions.chart.defaultLocale = newLocale

            if (this.$refs.statusChart) {
                this.$refs.statusChart.updateOptions(this.chartOptions, true, false)
                this.$refs.statusChart.updateSeries(this.chartSeries)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.apexcharts-legend {
    position: initial !important;
}

.apexcharts-legend-series {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 5px; /* Espaçamento entre itens da legenda */
}
</style>
