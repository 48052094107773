export const reportConfig = {
    account: '',
    client: '',
    site: '',
    reportName: '',
    dispatchTime: '',
    emails: [],
    period: '',
    nextDispatchDate: '',
    weeklyDay: null,
    monthlyDay: null,
    status: '',
    emailLanguage: '',
    deptLocalName: '',
    deptLocalId: '',
    sqlDataBaseName: '',
}
