var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('GmapMap',{ref:"map",staticStyle:{"height":"380px","width":"100%"},attrs:{"zoom":15,"options":{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            scrollwheel: false,
            disableDefaultUi: false,
        },"center":_vm.geolocation,"map-type-id":"roadmap"},on:{"center_changed":_vm.updateCenter,"zoom_changed":_vm.updateZoom}},[_c('GmapCircle',{attrs:{"center":_vm.circle.center,"radius":_vm.circle.radius,"options":_vm.circleOptions}}),_c('GmapMarker',{attrs:{"position":{
                lat: _vm.geolocation?.lat ? Number(_vm.geolocation.lat) : 0,
                lng: _vm.geolocation?.lng ? Number(_vm.geolocation.lng) : 0,
            }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }