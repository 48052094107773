<template>
    <div>
        <!-- Mapa Google -->
        <GmapMap
            ref="map"
            :zoom="15"
            :options="{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                scrollwheel: false,
                disableDefaultUi: false,
            }"
            :center="geolocation"
            map-type-id="roadmap"
            style="height: 380px; width: 100%"
            @center_changed="updateCenter"
            @zoom_changed="updateZoom"
        >
            <!-- Círculo desenhado no mapa -->
            <GmapCircle :center="circle.center" :radius="circle.radius" :options="circleOptions" />

            <!-- Marcador -->
            <GmapMarker
                :position="{
                    lat: geolocation?.lat ? Number(geolocation.lat) : 0,
                    lng: geolocation?.lng ? Number(geolocation.lng) : 0,
                }"
            />
        </GmapMap>
    </div>
</template>

<script>
import * as VueGoogleMaps from 'vue2-google-maps'

export default {
    props: ['location', 'radius'],
    watch: {
        location: function (newLocation) {
            if (Array.isArray(newLocation) && newLocation.length === 2) {
                this.geolocation = { lat: newLocation[0], lng: newLocation[1] }
                this.circle.center = this.geolocation
            }
        },
        radius: function (newRadius) {
            if (newRadius) {
                this.circle.radius = newRadius
            }
        },
    },
    components: {
        GmapMap: VueGoogleMaps.Map,
        GmapCircle: VueGoogleMaps.Circle,
        GmapMarker: VueGoogleMaps.Marker,
    },
    data() {
        return {
            zoom: 12,
            geolocation: { lat: 0, lng: 0 }, // Inicialização como objeto
            circle: {
                center: { lat: 0, lng: 0 }, // Inicialização como objeto
                radius: 0,
            },
            circleOptions: {
                strokeColor: '#FF0000', // Cor da borda
                strokeOpacity: 0.8, // Opacidade da borda
                strokeWeight: 2, // Espessura da borda
                fillColor: '#FF0000', // Cor do preenchimento
                fillOpacity: 0, // Transparente (apenas borda visível)
            },
            markers: [],
        }
    },
    methods: {
        updateCenter() {
            const map = this.$refs.map.$mapObject
            this.geolocation = {
                lat: map.getCenter().lat(),
                lng: map.getCenter().lng(),
            }
        },
        updateZoom() {
            const map = this.$refs.map.$mapObject
            this.zoom = map.getZoom()
        },
        getLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        this.geolocation = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        }
                        this.circle.center = this.geolocation
                    },
                    error => {
                        console.error(error)
                    },
                )
            }
        },
    },
    mounted() {
        if (Array.isArray(this.location) && this.location[0] === 0 && this.location[1] === 0) {
            this.getLocation()
        } else if (Array.isArray(this.location)) {
            this.geolocation = { lat: this.location[0], lng: this.location[1] }
            this.circle.center = this.geolocation
            this.circle.radius = this.radius
        }
    },
}
</script>
